<template>
  <div class="container">
    <h1>模拟安徽业务系统登录成功之后进行IDP登录</h1>
    <h3>说明如下：</h3>
    <p>目前模拟安徽系统账户表中有三个用户test1、test2、test3，密码都固定写1进行IDP登录</p>
    <p>模拟有两个可用的智慧芽账号：zhihuiya1@ahippc.cn、zhihuiya2@ahippc.cn</p>
    <p>登录成功之后会寻找一个未绑定或者最早绑定的智慧芽账号进行绑定，具体逻辑见IDP源码</p>
    <p>跳转智慧芽工作空间需要修改成如下地址：</p>
    <p>https://passport.zhihuiya.com/saml/login?idp=anhui-idp&redirect_uri=https%3A%2F%2Fworkspace.zhihuiya.com</p>
    <p>参数idp为智慧芽后台配置的值，目前设置的是anhui-idp，参数redirect_uri值为要访问智慧芽页面的链接地址 urlencode 后的字符串</p>
    <br>
    <br>
    <h3>Demo演示如下：</h3>
    <div class="user-info">
      <div>1、模拟安徽系统登录成功之后传递过来的用户名(可填入test1、test2或test3进行模拟，默认为test1)：</div>
      <input v-model="userName"/>
      <button style="margin-left: 20px;" @click="login">请点击模拟进行登录IDP</button>
    </div>
    <br>
    <div>
      <div>2、点击跳转智慧芽页面之前安徽系统需判断用户是否登录了再进行跳转，否则引导用户去登录，demo仅作示意：</div>
      <div class="link" @click="jump">
        https://passport.zhihuiya.com/saml/login?idp=anhui-idp&redirect_uri=https%3A%2F%2Fworkspace.zhihuiya.com
      </div>
    </div>
    <br>
    <br>
    <h3>流程图如下：</h3>
    <img alt="流程图" src="../assets/uml.jpg" style="width: 900px; height: 803px;">
    <h3>智慧芽后台配置：</h3>
    <img alt="智慧芽后台配置" src="../assets/config.png" style="width: 820px; height: 861px;">
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: 'HelloWorld',
  data() {
    return {
      userName: 'test1',
      // 根据安徽系统的实际情况判断用户是否登录
      isLogin: true
    }
  },
  methods: {
    login() {
      if (this.userName !== undefined && this.userName !== '') {
        // 模拟用户在安徽系统登录成功之后再进行IDP登录
        axios.get('/this-is-idp/login')
            .then(response => {
              let reg = /(?<=\<input.*value=\").*?(?=\")/g
              var newstr = reg.exec(response.data)
              // console.log(newstr[0])

              const params = new URLSearchParams()
              params.append('username', this.userName)
              params.append('password', '1')
              params.append('_csrf', newstr[0])

              axios.post('/this-is-idp/login', params, {
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded'
                }
              })
              .then(response => {
                alert('IDP登录成功，demo调试使用，集成请注释掉')
              })
              .catch(error => {
                console.error(error)
              })
            })
      }
    },
    jump() {
      // 安徽系统在用户点击链接之前自行判断用户是否已经登录业务系统
      if (this.isLogin) {
        window.open("https://passport.zhihuiya.com/saml/login?idp=anhui-idp&redirect_uri=" + encodeURIComponent('https://workspace.zhihuiya.com'))
      } else {
        alert('引导用户去业务系统登录')
      }
    }
  }
}
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.user-info {
  display: flex;
  flex-direction: row;
}

.link {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.link:hover {
  text-decoration: none;
}
</style>
